import React from 'react'

import SEO from '../components/seo'
import TypedText from '../components/typed-text'

const NotFoundPage = () => (
  <div className="text-content">
    <SEO title="404" />
    <h1 className="text-5xl sm:text-6xl text-teal-500 font-bold leading-none mb-4">Page not found</h1>
    <h2 className="font-bold mb-4 text-2xl inline-block">
      <TypedText text="Sorry about that. :(" />
    </h2>
  </div>
)

export default NotFoundPage
